import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tags } from '@tryghost/helpers-gatsby';
import { IPost } from '../utils/entities';
import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

export const Wrapper = styled(Container)`
  margin-bottom: 2rem;
`;

export const TagsWrapper = styled.div`
  text-transform: uppercase;
  color: #2e598f;
  font-weight: bold;
  font-size: 3rem;
  margin: 1rem 0;
`;

export const FeatureImage = styled.figure`
  img {
    width: 100%;
    height: auto;
  } 
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`;
export const Title = styled.h2`
  color: #535b6c;
  font-weight: bold;
  font-size: 28px;
`;
export const Details = styled.span`
  color: #9aa0a6;
  font-weight: bold;
  font-size: 1rem;
`;

export const Content = styled.section`
  color: #535b6c;
  font-size: 18px;
  img {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }
  margin-bottom: 8rem;
`;

export const RelatedPosts = styled.h2`
  margin: 1rem 0;
  color: #535b6c;
  font-weight: bold;
  font-size: 28px;
`;

export const ViewMore = styled.p`
  a {
    color: #2e598f;
    font-size: 1.2rem;
  }
  text-align: center;
  margin: 5rem;
`;

interface IPostContent {
  post: IPost;
}

const PostContent = ({
    post,
}: IPostContent): ReactElement => {
    console.log(post);
    return (
        <Wrapper>
            <Row noGutters>
                <TagsWrapper>
                    <Tags
                        post={post}
                        visibility="public"
                        autolink={false}
                        separator=" | "
                    />
                </TagsWrapper>
            </Row>
            <Row noGutters>
                <Col>
                    <article>
                        {post.feature_image && (
                            <FeatureImage>
                                <img src={post.feature_image} alt={post.title} />
                            </FeatureImage>
                        )}
                        <section>
                            <Header>
                                <Title>{post.title}</Title>
                                <Details>
                  on {post.published_at_pretty} / by{` `}
                                    {post.primary_author.name}
                                </Details>
                            </Header>

                            {/* The main post content */}
                            <Content
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </Col>
            </Row>
            <Row noGutters>
                <Col lg="12">
                    <ViewMore>
                        <Link to="/blog">View More Blogs</Link>
                    </ViewMore>
                </Col>
            </Row>
        </Wrapper>
    );
};

export default PostContent;
