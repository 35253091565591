import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { IPost } from '../utils/entities';
import { Container } from 'react-bootstrap';
import PostContent from '../components/postContent';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/

interface IPostPage {
    data: {
      ghostPost: IPost;
      allGhostPost: {
        edges: {
          node: IPost;
        }[];
      };
    };
    location: {
      pathname: string;
    };
    pageContext: {
      tags: string[];
    };
  }

const Post = ({ data, location }: IPostPage): ReactElement => {
    const post = data.ghostPost;

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <Container>
                    <PostContent post={post} />
                </Container>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
  query($slug: String!, $tags: [String!]) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    allGhostPost(
      sort: { fields: published_at, order: DESC }
      filter: { tags: { elemMatch: { slug: { in: $tags } } } }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
